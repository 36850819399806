var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    comboItems: _vm.useFlagItems,
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "useFlag",
                    label: "사용여부",
                  },
                  model: {
                    value: _vm.searchParam.useFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "useFlag", $$v)
                    },
                    expression: "searchParam.useFlag",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12 col-lg-4" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "위험형태 목록",
                  tableId: "riskType",
                  columns: _vm.grid.columns,
                  data: _vm.grid.data,
                  filtering: false,
                  columnSetting: false,
                  usePaging: false,
                },
                on: { rowClick: _vm.rowClick },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: { label: "검색", icon: "search" },
                          on: { btnClicked: _vm.getList },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-12 col-lg-8" },
          [
            _c(
              "q-form",
              { ref: "editForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "위험형태 상세" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "등록", icon: "add" },
                                  on: { btnClicked: _vm.addData },
                                })
                              : _vm._e(),
                            _vm.saveEditable
                              ? _c("c-btn", {
                                  attrs: {
                                    isSubmit: _vm.isSave,
                                    url: _vm.saveUrl,
                                    param: _vm.riskType,
                                    mappingType: _vm.mappingType,
                                    label: "저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveRiskType,
                                    btnCallback: _vm.saveRiskTypeCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              disabled: !_vm.saveMode,
                              editable: _vm.editable,
                              required: true,
                              label: "위험형태",
                              name: "riskTypName",
                            },
                            model: {
                              value: _vm.riskType.riskTypName,
                              callback: function ($$v) {
                                _vm.$set(_vm.riskType, "riskTypName", $$v)
                              },
                              expression: "riskType.riskTypName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-radio", {
                            attrs: {
                              disabled: !_vm.saveMode,
                              editable: _vm.editable,
                              comboItems: _vm.useFlagItems,
                              label: "사용여부",
                              name: "useFlag",
                            },
                            model: {
                              value: _vm.riskType.useFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.riskType, "useFlag", $$v)
                              },
                              expression: "riskType.useFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c(
                            "c-table",
                            {
                              ref: "causeTable",
                              attrs: {
                                title: "위험형태별 원인 목록",
                                columns: _vm.gridCasue.columns,
                                gridHeight: _vm.gridCasue.height,
                                data: _vm.riskType.causes,
                                filtering: false,
                                columnSetting: false,
                                usePaging: false,
                                editable: _vm.saveEditable,
                                selection: "multiple",
                                rowKey: "ramRiskTypeCauseId",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "table-button" },
                                [
                                  _c(
                                    "q-btn-group",
                                    { attrs: { outline: "" } },
                                    [
                                      _vm.saveEditable
                                        ? _c("c-btn", {
                                            attrs: {
                                              showLoading: false,
                                              label: "추가",
                                              icon: "add",
                                            },
                                            on: { btnClicked: _vm.addCause },
                                          })
                                        : _vm._e(),
                                      _vm.saveEditable
                                        ? _c("c-btn", {
                                            attrs: {
                                              showLoading: false,
                                              label: "제외",
                                              icon: "remove",
                                            },
                                            on: { btnClicked: _vm.deleteCause },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }